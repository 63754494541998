import sha3 from 'crypto-js/sha3'
import * as bs58check from 'bs58check'

export const isValidETHAddress = function(address) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    return false
  } else {
    address = address.replace('0x', '')
    var addressHash = sha3(address.toLowerCase())
    for (var i = 0; i < 40; i++) {
      if (
        (parseInt(addressHash[i], 16) > 7 &&
          address[i].toUpperCase() !== address[i]) ||
        (parseInt(addressHash[i], 16) <= 7 &&
          address[i].toLowerCase() !== address[i])
      ) {
        return false
      }
    }
    return true
  }
}

export function isValidBTCAddress(address) {
  try {
    bs58check.decode(address)
  } catch (e) {
    return false
  }

  return true
}
