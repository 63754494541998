import React from 'react'
import axios from 'axios'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'
import { Section } from 'src/components/shared'

import styles from './StatsBanner.module.scss'
import MeerKat from '../../../../assets/images/inline/meerkat.svg'
import Binoculars from '../../../../assets/images/inline/binoculars.svg'

export default class StatsBanner extends React.Component {
  state = {
    addresses: '',
    notifications: '',
  }

  componentDidMount() {
    import('jquery').then(res => {
      window.jQuery = res.default
      return import('@fancyapps/fancybox') 
    })
    .catch(console.error)
    
    axios
      .all([
        axios.get('/api/v0/subscriptions/stats/addresses'),
        axios.get('/api/v0/subscriptions/stats/notifications'),
      ])
      .then(([addresses, notifications]) =>
        this.setState({
          addresses: Number(addresses.data).toLocaleString(),
          notifications: Number(notifications.data).toLocaleString(),
        })
      )

      
  }

  render() {
    const { addresses, notifications } = this.state

    return (
      <Section size="lg" className={styles.outer} innerClassName={styles.inner}>
        <MeerKat className={styles.meerkat} />
        <div className={styles.container}>
          <div className={styles.stat}>
            <h2 className={styles.statNumber}>{addresses}</h2>
            <span className={styles.statLabel}>Addresses Watched</span>
          </div>
          <div className={styles.stat}>
            <h2 className={styles.statNumber}>{notifications}</h2>
            <span className={styles.statLabel}>Notifications Sent</span>
          </div>
        </div>
        <a
          data-fancybox 
          data-width="560" 
          data-height="315"
          href="https://youtu.be/gWNIJkLZD0U"
          className={styles.watch}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Binoculars className={styles.binoculars} /> Watch meerkats work
        </a>
      </Section>
    )
  }
}
