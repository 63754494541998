import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './Label.scss'

class Label extends React.PureComponent {
  render() {
    const {
      children,
      className,
      textClassName,
      inline,
      isRequired,
      error,
      text,
      showError,
    } = this.props
    
    return (
      <label className={cx('Label', className, { 'Label--inline': inline })}>
        {text && (
          <span
            className={cx('Label-text', textClassName, {
              'is-required': isRequired,
              'has-error': error,
            })}
          >
            {text}
          </span>
        )}

        {children}

        {error && showError && (
          <span className="Label-error" key={error}>
            {error}
          </span>
        )}
      </label>
    )
  }
}

Label.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  htmlFor: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Label
