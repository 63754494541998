import React from 'react'

import Layout from '../components/layout'
import Navbar from '../components/shared/Navbar'
import Header from '../components/pages/Home/Header'
import StatsBanner from '../components/pages/Home/StatsBanner'
import HowItWorks from '../components/pages/Home/HowItWorks'
import Enterprise from '../components/pages/Home/Enterprise'
import FAQ from '../components/pages/Home/FAQ'
import Footer from '../components/pages/Home/Footer'
import Monitoring from '../components/pages/Home/Monitoring'

class IndexPage extends React.Component {
  state = {
    isMobile: false,
  }

  componentDidMount() {
    this.setState({ isMobile: window.matchMedia('(max-width: 960px)').matches })
  }

  render() {
    return (
      <Layout>
        <Navbar isHome />
        <Header location={this.props.location} />
        <StatsBanner />
        <HowItWorks />
        <Monitoring />
        <Enterprise />
        <FAQ />
        <Footer isHome isMobile={this.state.isMobile} />
      </Layout>
    )
  }
}

export default IndexPage
