import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import cx from 'classnames'

import './LinkButton.scss'

const LinkButton = ({
  breakLine,
  children,
  to,
  icon,
  dataTest,
  disabled,
  className,
  theme,
  size,
  ...attrs
}) => {
  return (
    <Link
      className={cx('Link', `Link--${theme}`, `Link--${size}`, className, {
        'Link--icon': icon,
        'Link--breakLine': breakLine,
        disabled,
      })}
      data-test={dataTest}
      to={to}
      {...attrs}
    >
      {children}
    </Link>
  )
}

LinkButton.defaultProps = {
  text: 'Button',
  size: 'md',
  theme: 'primary',
}

LinkButton.propTypes = {
  breakLine: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  text: PropTypes.string,
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link']),
  to: PropTypes.string,
}

export default LinkButton
