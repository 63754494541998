import React from 'react'
import Loading from '../Loading'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './Button.scss'

const Button = props => {
  const {
    text,
    dataTest,
    loading,
    className,
    theme,
    size,
    onClick,
    type = 'button',
    ...attrs
  } = props

  const classes = cx('Btn', `Btn--${theme}`, `Btn--${size}`, className, {
    'is-loading': loading,
  })

  return (
    <button
      className={classes}
      onClick={loading ? () => {} : onClick}
      data-test={dataTest}
      disabled={loading}
      type={type}
      {...attrs}
    >
      {loading && <Loading />}
      {text}
    </button>
  )
}

Button.defaultProps = {
  text: 'Button',
  size: 'md',
  theme: 'primary',
}

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xlg']),
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link']),
  className: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
}

export default Button
