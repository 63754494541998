import React from 'react'
import { Button } from '../../../shared'

import Envelope from '../../../../assets/images/envelope.svg'
import styles from './SubscriptionSuccess.module.scss'

export default ({ toggle }) => {
  return (
    <React.Fragment>
      <img src={Envelope} alt="Envelope" />
      <h2 className={styles.title}>You aren’t done</h2>
      <p className={styles.description}>
        Check your email to verify it and start monitoring!
      </p>
      <p className={styles.description}>Thanks for using Meerkat!</p>
      <Button onClick={toggle} text="Watch Another Address" />
    </React.Fragment>
  )
}
