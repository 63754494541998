import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames/bind'
import { Button, Section } from 'src/components/shared'
import { animateScroll as scroll } from 'react-scroll'

import styles from './HowItWorks.module.scss'

import Pipe from '../../../../assets/images/inline/how-it-works-pipe.svg'

const cx = classNames.bind(styles)

export default class HowItWorks extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            howItWorks: file(relativePath: { eq: "how-it-works.png" }) {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <Section
              size="lg"
              className={styles.outer}
              innerClassName={styles.inner}
              name="how-it-works"
            >
              <Pipe className={styles.pipe} />
              <h2 className={styles.title}>How Meerkat Works</h2>
              <h3 className={styles.subtitle}>
                Closely monitor blockchain addresses, for any reason
              </h3>
              <ul className={styles.steps}>
                <li className={styles.step}>01</li>
                <li className={styles.step}>02</li>
                <li className={styles.step}>03</li>
              </ul>
              
              <Img
                lazy
                fluid={data.howItWorks.childImageSharp.fluid}
                fadeIn={false}
                alt="How it works"
              />
              <ul className={cx('steps', 'stepsWithSpace')}>
                <li className={cx('step', 'stepSmall')}>
                  Enter your wallet address
                </li>
                <li className={cx('step', 'stepSmall')}>
                  Our meerkats monitor
                  <br /> your wallet 24/7
                </li>
                <li className={cx('step', 'stepSmall')}>
                  Get instant alerts for
                  <br /> transactions in or out
                </li>
              </ul>

              <Button
                className={styles.watch}
                text="Watch An Address"
                theme="secondary"
                onClick={() => {
                  scroll.scrollToTop()
                }}
              />
              
            </Section>
          )
        }}
      />
    )
  }
}
