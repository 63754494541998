import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'
import { withFormik } from 'formik'
import { parse } from 'qs'
import * as Yup from 'yup'
import axios from 'axios'
import EthValidator from 'ethereum-address'

import { Button, Checkbox, Input, Label } from 'src/components/shared'
import {
  isValidBTCAddress,
} from '../../../../utils/addressValidations'

import BTC from 'src/assets/images/inline/margin/btc.svg'
import ETH from 'src/assets/images/inline/margin/eth.svg'
import LTC from 'src/assets/images/inline/margin/ltc.svg'
import DOGE from 'src/assets/images/inline/margin/doge.svg'

import styles from './SubscriptionForm.module.scss'

let cx = classNames.bind(styles)

function omit(object, ...values) {
  return Object.keys(object)
    .filter(key => !values.includes(key))
    .reduce((newObject, key) => {
      newObject[key] = object[key]
      return newObject
    }, {})
}

function trim(object) {
  return Object.keys(object).reduce((trimmedValues, key) => {
    if (typeof object[key] === 'string') {
      trimmedValues[key] = object[key].trim()
    } else {
      trimmedValues[key] = object[key]
    }
    return trimmedValues
  }, {})
}

class SubscriptionForm extends React.Component {
  state = {}

  render() {
    const {
      errors,
      handleChange,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      touched,
      values,
    } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Label text="Which asset type do you want to watch?">
          <ul
            className={cx('radioGroup', {
              radioGroupEthActive: values.currency === 'ETH',
            })}
          >
            <li className={styles.radioItem}>
              <label
                className={cx('radioButton', {
                  radioButtonActive: values.currency === 'BTC',
                })}
              >
                <Input
                  checked={values.currency === 'BTC'}
                  value="BTC"
                  name="currency"
                  type="radio"
                  className={styles.radio}
                  onChange={handleChange}
                />
                <BTC
                  className={styles.icon}
                  fill={values.currency === 'BTC' ? '#fff' : '#B3B5B7'}
                />
                <span className={styles.radioText}>BTC</span>
              </label>
            </li>
            <li className={styles.radioItem}>
              <label
                className={cx('radioButton', {
                  radioButtonActive: values.currency === 'ETH',
                })}
              >
                <Input
                  checked={values.currency === 'ETH'}
                  value="ETH"
                  name="currency"
                  type="radio"
                  className={styles.radio}
                  onChange={handleChange}
                />
                <ETH
                  className={styles.icon}
                  fill={values.currency === 'ETH' ? '#fff' : '#B3B5B7'}
                />
                <span className={styles.radioText}>ETH</span>
                <ul
                  className={cx('radioGroup', 'radioGroupErc20', {
                    radioGroupErc20Active: values.currency === 'ETH',
                  })}
                >
                  <li className={cx('radioItem', 'radioItemErc20')}>
                    <label
                      className={cx('radioButton', {
                        radioButtonActive: values.includeERC20 === 'true',
                      })}
                    >
                      <Input
                        checked={values.includeERC20 === 'true'}
                        value={'true'}
                        name="includeERC20"
                        type="radio"
                        className={styles.radio}
                        onChange={handleChange}
                      />
                      <span className={styles.radradioTextErc20ioText}>
                        Include ERC-20
                      </span>
                    </label>
                  </li>
                  <li className={styles.radioItem}>
                    <label
                      className={cx('radioButton', {
                        radioButtonActive: values.includeERC20 === 'false',
                      })}
                    >
                      <Input
                        checked={values.includeERC20 === 'false'}
                        value={'false'}
                        name="includeERC20"
                        type="radio"
                        className={styles.radio}
                        onChange={handleChange}
                      />
                      <span className={styles.radioTextErc20}>Exclude</span>
                    </label>
                  </li>
                </ul>
              </label>
            </li>
            <li className={styles.radioItem}>
              <label
                className={cx(styles.radioButton, {
                  radioButtonActive: values.currency === 'LTC',
                })}
              >
                <Input
                  checked={values.currency === 'LTC'}
                  value="LTC"
                  type="radio"
                  name="currency"
                  className={styles.radio}
                  onChange={handleChange}
                />
                <LTC
                  className={styles.icon}
                  fill={values.currency === 'LTC' ? '#fff' : '#B3B5B7'}
                />
                <span className={styles.radioText}>LTC</span>
              </label>
            </li>
            <li className={styles.radioItem}>
              <label
                className={cx('radioButton', {
                  radioButtonActive: values.currency === 'DOGE',
                })}
              >
                <Input
                  checked={values.currency === 'DOGE'}
                  value="DOGE"
                  type="radio"
                  name="currency"
                  className={styles.radio}
                  onChange={handleChange}
                />
                <DOGE
                  className={styles.icon}
                  fill={values.currency === 'DOGE' ? '#fff' : '#B3B5B7'}
                />
                <span className={styles.radioText}>DOGE</span>
              </label>
            </li>
          </ul>
        </Label>
        <Label
          text="Wallet Address"
          error={touched.address && errors.address}
          showError
        >
          <Input
            name="address"
            value={values.address}
            type="text"
            onChange={handleChange}
            hasError={touched.address && errors.address}
          />
        </Label>
        <Label text="Your Email Address" error={touched.email && errors.email} showError>
          <Input
            name="email"
            value={values.email}
            type="email"
            onChange={handleChange}
            hasError={touched.email && errors.email}
          />
        </Label>
        <Label text="Address Nickname" error={touched.name && errors.name} showError>
          <Input
            name="name"
            type="text"
            onChange={handleChange}
            hasError={touched.name && errors.name}
            value={values.name}
          />
        </Label>
        <Label error={touched.terms && errors.terms} showError>
          <Checkbox
            checked={values.terms}
            name="terms"
            id="terms"
            aria-label="Terms and conditions"
            onChange={() => setFieldValue('terms', !values.terms)}
            renderLabel={() => (
              <React.Fragment>
                I accept{' '}
                <Link className={styles.terms} to="/terms">
                  {' '}
                  Terms and Conditions{' '}
                </Link>
              </React.Fragment>
            )}
          />
        </Label>

        <Button
          className={styles.button}
          text="Watch"
          type="submit"
          disabled={isSubmitting}
        />
        <span className={styles.span}>No account required</span>
      </form>
    )
  }
}

const defaults = {
  name: '',
  currency: 'BTC',
  includeERC20: 'true',
  address: '',
  email: '',
  terms: '',
}

export default withFormik({
  validateOnChange: false,
  validateOnBlur: true,
  mapPropsToValues: props => {
    const { name, currency, address, email } = parse(props.location.search, {
      ignoreQueryPrefix: true,
    })

    return {
      name: name || defaults.name,
      currency: currency || defaults.currency,
      address: address || defaults.address,
      email: email || defaults.email,
      terms: '',
      includeERC20: 'true',
    }
  },
  handleSubmit: (values, { setSubmitting, setFieldError, setFieldTouched, setValues, props }) => {
    axios
      .post(`/api/v0/subscriptions`, {
        ...trim(omit(values, 'terms', 'includeERC20')),
        ...(values.currency === 'ETH'
          ? { includeERC20: values.includeERC20 === 'true' }
          : {}),
      })
      .then(res => {
        setSubmitting(false)
        setValues(defaults)
        props.toggle()
      })
      .catch(err => {
        setFieldError('terms', 'Failed to register subscription. Please try again.' )
        setSubmitting(false)
      })
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('Name is required.')
      .matches(
        /^[A-Za-z0-9\s']{1,80}$/,
        "Only alphanumerics, apostrophes, and spaces are allowed."
      ),
    terms: Yup.bool().required('Please accept terms and conditions.'),
    email: Yup.string()
      .email('Email Address is not valid.')
      .required('Email Address is required.'),
    currency: Yup.string().required('Currency is required.'),
    address: Yup.string()
      .required('Address Type is required.')
      .when('currency', {
        is: 'ETH',
        then: Yup.string()
          .test('address', 'ETH Address failed checksum.', EthValidator.isChecksumAddress)
          .test('address', 'ETH Address is invalid.', EthValidator.isAddress),
      })
      .when('currency', {
        is: 'BTC',
        then: Yup.string().test(
          'address',
          'Bitcoin Address is invalid.',
          isValidBTCAddress
        ),
      })
      .when('currency', {
        is: 'DOGE',
        then: Yup.string().test(
          'address',
          'DOGE Address is invalid.',
          isValidBTCAddress
        ),
      })
      .when('currency', {
        is: 'LTC',
        then: Yup.string()
          .matches(/^[ML]/, 'LTC Address must start with "M" or "L".')
          .test('address', 'LTC Address is invalid.', isValidBTCAddress),
      }),
  }),
})(SubscriptionForm)
