import React from 'react'
import ReactModal from 'react-modal'
import cx from 'classnames'
import CloseIcon from 'src/assets/images/inline/close-gray.svg'

import './Modal.scss'

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#___gatsby')

class Modal extends React.Component {
  render() {
    const { children, className, closeModal, isOpen, size, title } = this.props
    const classes = cx('Modal', { [`Modal-${size}`]: size }, className, {
      isOpen: isOpen,
    })

    return (
      <ReactModal
        className={classes}
        closeTimeoutMS={300}
        contentLabel={typeof title === 'string' ? title : 'Modal'}
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        role="dialog"
      >
        <header className="Modal-header">
          <button
            onClick={closeModal}
            aria-label="Close this modal"
            className="Modal-closeButton"
          >
            <CloseIcon />
          </button>
          {title && <h1 className="Modal-title">{title}</h1>}
        </header>
        <div className="Modal-body">{children}</div>
      </ReactModal>
    )
  }
}

export default Modal
