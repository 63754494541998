import React from 'react'
import cx from 'classnames'

import './Hamburger.scss'

export default ({ isOpen, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      aria-label="Hamburger menu"
      aria-controls="Navbar-mobile"
      aria-expanded={isOpen}
      aria-haspopup="true"
      className={cx('hamburger hamburger--spin', className, { 'is-active': isOpen })}
      type="button"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  )
}
