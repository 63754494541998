import React from 'react'
import {  ScrollElement } from 'react-scroll'
import classNames from 'classnames/bind'

import styles from './Section.module.scss'

let cx = classNames.bind(styles)

const Section = ({
  children,
  className,
  innerClassName,
  name,
  id,
  size = 'sm',
  withOutGutters,
  ...rest
}) => {
  let sizeCase = size.slice(0, 1).toUpperCase() + size.slice(1)
  return (
    <section
      className={cx(styles.Section, className, styles[`Section${sizeCase}`])}
      name={name}
      id={id}
      {...rest}
    >
      <div
        className={cx(
          'SectionInner',
          innerClassName,
          styles[`SectionInner${sizeCase}`],
          {
            [styles.withoutGutters]: withOutGutters,
          }
        )}
      >
        {children}
      </div>
    </section>
  )
}

export default ScrollElement(Section)
