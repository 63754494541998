import React, { Component } from 'react'
import axios from 'axios'
import { Button, Input, Label } from 'src/components/shared'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import styles from './EnterpriseRequestForm.module.scss'

class EnterpriseRequestForm extends Component {
  render() {
    const { errors, handleChange, handleSubmit, touched, values, isSubmitting } = this.props

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <h4 className={styles.title}>Inquire about Meerkat Enterprise</h4>
        <Label text="Name">
          <Input
            autoComplete="new-password"
            name="name"
            onChange={handleChange}
            hasError={touched.name && errors.name}
            value={values.name}
          />
        </Label>
        <Label text="Email">
          <Input
            autoComplete="new-password"
            type="email"
            name="email"
            onChange={handleChange}
            hasError={touched.email && errors.email}
            value={values.email}
          />
        </Label>
        <Label text="Company URL">
          <Input
            autoComplete="new-password"
            name="company"
            onChange={handleChange}
            hasError={touched.company && errors.company}
            value={values.company}
          />
        </Label>
        <Label text="Role (optional)" error={errors.role}>
          <Input
            autoComplete="new-password"
            name="role"
            onChange={handleChange}
            hasError={touched.role && errors.role}
            value={values.role}
          />
        </Label>
        <Button className={styles.button} text="Request Trial" type="submit" loading={isSubmitting} />

      </form>
    )
  }
}

const defaults = {
  name: '',
  email: '',
  company: '',
  role: '',
}

export default withFormik({
  validateOnChange: false,
  validateOnBlur: true,
  mapPropsToValues: props => ({ ...defaults }),
  handleSubmit: (values, { setSubmitting, setValues, setError, props }) => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbxCbTU6DMfJ0yh9seCG55Nonsl6Y9RE38bxmYVfIBntc5-FejT_/exec`,
        {
          params: {
            ...values,
            date: new Date(),
          },
        }
      )
      .then(res => {
        setSubmitting(false)
        setValues({ ...defaults })
      })
      .catch(err => {
        setSubmitting(false)
        setError('role', 'There was an error submitting your request.')
      })
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string()
      .email('Email address is not valid.')
      .required('Email address is required.'),
    company: Yup.string().required('Address Type is required.'),
  }),
})(EnterpriseRequestForm)
