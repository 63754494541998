import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './Checkbox.scss'

class Checkbox extends React.PureComponent {
  state = {
    isFocused: false,
  }

  render() {
    const { isFocused } = this.state
    const {
      checked = false,
      disabled,
      label,
      onChange,
      renderLabel,
      ...attrs
    } = this.props

    return (
      <label className={cx('Checkbox-label', this.props.className)}>
        <input
          disabled={disabled}
          onClick={() => this.setState({ isFocused: false }, onChange)}
          type="checkbox"
          className={cx('Checkbox', {
            'is-checked': checked,
            disabled: disabled,
            tabbed: isFocused,
          })}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              this.setState({ isFocused: false }, onChange)
            }
          }}
          checked={checked}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
          readOnly
          {...attrs}
        />
        <span
          className={cx('Checkbox-text', {
            'is-checked': checked,
            'is-focused': isFocused,
            'without-label': !renderLabel && !label,
            disabled,
          })}
        >
          {renderLabel ? renderLabel() : label}
        </span>
      </label>
    )
  }
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  label: PropTypes.string,
}

export default Checkbox
