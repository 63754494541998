import React from 'react'
import { Section } from '../../../shared'
import classNames from 'classnames/bind'

import styles from './FAQ.module.scss'

const cx = classNames.bind(styles)

class FAQ extends React.Component {
  constructor() {
    super()
    this.state = { toggled: new Set() }

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    const { name } = e.currentTarget
    const { toggled } = this.state

    if (toggled.has(name)) {
      toggled.delete(name)
    } else {
      toggled.add(name)
    }

    this.setState({ toggled })
  }

  render() {
    return (
      <Section className={styles.faqSection} name="faq" size={'md'}>
        <h2 className={styles.title}>FAQ</h2>
        {this.state.toggled}
        <div className={styles.row}>
          <button
            name="what"
            className={cx('question', {
              active: this.state.toggled.has('what'),
            })}
            onClick={this.onClick}
          >
            What does Meerkat do?
          </button>
          <div
            className={cx('answer', { isOpen: this.state.toggled.has('what') })}
          >
            <span className={styles.answerText}>
              Meerkat is a blockchain wallet monitoring service that notifies
              users via email anytime funds are sent to or from the wallet
              address being monitored.
            </span>
            <span className={styles.answerText}>
              Notifications are sent as soon as a tx containing the monitored
              wallet posts to the blockchain.
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <button
            name="pay"
            className={cx('question', {
              active: this.state.toggled.has('pay'),
            })}
            onClick={this.onClick}
          >
            Do I have to pay to use Meerkat?
          </button>
          <div
            className={cx('answer', { isOpen: this.state.toggled.has('pay') })}
          >
            <span className={styles.answerText}>
              Meerkat is a completely free service to all users during our beta
              period.
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <button
            name="how"
            className={cx('question', {
              active: this.state.toggled.has('how'),
            })}
            onClick={this.onClick}
          >
            How can I use this for my business?
          </button>
          <div
            className={cx('answer', { isOpen: this.state.toggled.has('how') })}
          >
            <span className={styles.answerText}>
              <span className={cx('answerHeader', 'answerHeaderInline')}>Meerkat Enterprise</span>{' '}
              offers a suite of additional monitoring and notification services.
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <button
            name="why"
            className={cx('question', {
              active: this.state.toggled.has('why'),
            })}
            onClick={this.onClick}
          >
            Why use Meerkat?
          </button>
          <div
            className={cx('answer', { isOpen: this.state.toggled.has('why') })}
          >
            <span className={styles.answerHeader}>
              Monitor Paper/Offline/Cold Storage Wallets
            </span>
            <span className={styles.answerText}>
              Meerkat enables users whose private keys are stored offline to be
              notified any time funds move to or from their address. Meerkat is
              a solution for those who wish to keep an extra eye on their
              wallets.
            </span>
            <span className={styles.answerHeader}>
              Notify/Receipt of Transactions
            </span>

            <span className={styles.answerText}>
              Meerkat enables users to be notified of expected, or unexpected
              transactions. Each transaction to or from the monitored address
              sends a unique receipt to the subscription email address.
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <button
            name="which"
            className={cx('question', {
              active: this.state.toggled.has('which'),
            })}
            onClick={this.onClick}
          >
            Which cryptocurrency addresses can I watch?
          </button>
          <div
            className={cx('answer', {
              isOpen: this.state.toggled.has('which'),
            })}
          >
            <span className={styles.answerText}>
              Bitcoin, Ethereum (including ERC-20), Litecoin, Dogecoin
            </span>
          </div>
        </div>
      </Section>
    )
  }
}

export default FAQ
