import React from 'react'
import Rellax from 'rellax'
import classNames from 'classnames/bind'

import styles from './Header.module.scss'

const cx = classNames.bind(styles)

export default class Header extends React.Component {
  componentDidMount() {
    new Rellax('.rellax')
  }

  render() {
    return (
      <header className={cx(styles.header, this.props.className)}>
        <section className={styles.inner}>
          {this.props.title && (
            <h1 className={styles.title}>{this.props.title}</h1>
          )}
          {this.props.children}
        </section>
        <div
          className={`${cx('image', 'image1')} rellax`}
          data-rellax-speed="-10"
        />
        <div
          className={`${cx('image', 'image2')} rellax`}
          data-rellax-speed="-8"
        />
        <div
          className={`${cx('image', 'image3')} rellax`}
          data-rellax-speed="-6"
        />
        <div
          className={`${cx('image', 'image4')} rellax`}
          data-rellax-speed="-4"
        />
        <div
          className={`${cx('image', 'image5')} rellax`}
          data-rellax-speed="-3"
        />
        <div
          className={`${cx('image', 'image6')} rellax`}
          data-rellax-speed="-2"
        />
      </header>
    )
  }
}
