import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames/bind'
import { Section } from 'src/components/shared'

import styles from './Monitoring.module.scss'

import LeftPipe from '../../../../assets/images/inline/monitoring-left-pipe.svg'
import RightPipe from '../../../../assets/images/inline/monitoring-right-pipe.svg'

const cx = classNames.bind(styles)

export default class HowItWorks extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            monitoring: file(relativePath: { eq: "monitoring.png" }) {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <Section
              size="lg"
              className={styles.outer}
              innerClassName={styles.inner}
              name="technology"
            >
              <LeftPipe className={styles.leftPipe} />
              <h2 className={styles.title}>
                Best-in-class blockchain monitoring technology
              </h2>
              <h3 className={styles.subtitle}>
                with near-instant mempool detection and notification service
              </h3>
              <div className={styles.container}>
                <Img
                  fluid={data.monitoring.childImageSharp.fluid}
                  fadeIn={false}
                  alt="How it works"
                />
              </div>
              <ul className={cx('steps', 'stepsWithSpace')}>
                <li className={cx('step', 'stepSmall')}>
                  No account necessary and it's completely free!
                </li>
              </ul>
              <RightPipe className={styles.rightPipe} />
            </Section>
          )
        }}
      />
    )
  }
}
