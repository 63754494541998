import React from 'react'
import { Section } from '../../../shared'
import styles from './Enterprise.module.scss'
import EnterpriseRequestForm from '../EnterpriseRequestForm'
import EnterpriseMeerkat from '../../../../assets/images/enterprise-meerkats.svg'

import Pipe from '../../../../assets/images/inline/enterprise-pipe.svg'

export default () => (
  <Section
    size="lg"
    className={styles.enterpriseSection}
    innerClassName={styles.inner}
    name="enterprise"
  >
    <Pipe className={styles.pipe} />
    <h2 className={styles.title}>Interested in Meerkat for Enterprises?</h2>
    <h3 className={styles.subtitle}>For those in need of many Meerkats</h3>
    <img
      className={styles.image}
      src={EnterpriseMeerkat}
      alt="Enterprise Meerkat"
    />
    <div className={styles.container}>
      <div className={styles.bulletpoints}>
        <p className={styles.listTitle}>Features of Meerkat Enterprise</p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Enhanced notifications via phone calls, SMS and callback URLs
          </li>
          <li className={styles.listItem}>
            Define and track bad actor addresses
          </li>
          <li className={styles.listItem}>
            Integrate with your own app via our webhooks
          </li>
          <li className={styles.listItem}>
            Granular transaction confirmations (Detected, mined, confirmed)
          </li>
          <li className={styles.listItem}>Monitor Tx ID's</li>
          <li className={styles.listItem}>
            Watch unlimited addresses and extended public keys
          </li>
          <li className={styles.listItem}>Watch entire HD sets</li>
        </ul>
      </div>
      <div className={styles.form}>
        <EnterpriseRequestForm />
      </div>
    </div>
  </Section>
)
