import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './Input.scss'

class Input extends React.PureComponent {
  render() {
    const {
      hasError,
      disabled,
      className,
      textarea = false,
      size = 'sm',
      ...rest
    } = this.props

    return textarea ? (
      <textarea
        className={cx('TextArea', className, {
          'has-error': hasError,
          'is-disabled': disabled,
        })}
        disabled={disabled}
        {...rest}
      />
    ) : (
      <input
        className={cx('TextInput', className, `TextInput--${size}`, {
          'has-error': hasError,
          'is-disabled': disabled,
        })}
        disabled={disabled}
        {...rest}
      />
    )
  }
}

Input.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default Input
