import React from 'react'
import Link from 'gatsby-link'
import { Link as ScrollLink } from 'react-scroll'
import classNames from 'classnames/bind'
import Logo from '../../../assets/images/inline/logo.svg'

import { Hamburger } from 'src/components/shared'
import styles from './Navbar.module.scss'

const cx = classNames.bind(styles)

function throttle(callback, wait, context = this) {
  let timeout = null 
  let callbackArgs = null
  
  const later = () => {
    callback.apply(context, callbackArgs)
    timeout = null
  }
  
  return function() {
    if (!timeout) {
      callbackArgs = arguments
      timeout = setTimeout(later, wait)
    }
  }
}
export default class Navbar extends React.PureComponent {
  constructor() {
    super()

    this.state = {
      isOpen: false,
      isDocked: true,
    }

    this.onToggle = this.onToggle.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    return window.addEventListener('scroll', throttle(this.handleScroll, 10))
  }

  componentWillUnmount() {
    return window.removeEventListener('scroll', throttle(this.handleScroll, 10))
  }

  handleScroll(e) {
    if (window.scrollY !== 0) {
      this.setState({ isDocked: false })
    } else {
      this.setState({ isDocked: true })
    }
  }

  onToggle() {
    document.querySelector('html').classList.toggle('mobile-nav')
    document.querySelector('body').classList.toggle('mobile-nav')
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { isHome } = this.props

    return (
      <nav className={cx('navbar', { 'navbarIsScrolled': !this.state.isDocked })}>
        <div className={styles.inner}>
          <Link to="/" aria-label="Home page">
            <Logo className={styles.logo} />
          </Link>

          {isHome && (
            <Hamburger
              isOpen={this.state.isOpen}
              className={styles.toggle}
              onClick={this.onToggle}
            />
          )}

          {isHome && (
            <div className={styles.scrollLinks}>
              <ScrollLink
                href="#how-it-works"
                className={styles.link}
                activeClass={styles.activeLink}
                to="how-it-works"
                spy={true}
                offset={-160}
                smooth={true}
                duration={500}
              >
                How It Works
              </ScrollLink>

              <ScrollLink
                href="#technology"
                className={styles.link}
                activeClass={styles.activeLink}
                to="technology"
                spy={true}
                offset={-160}
                smooth={true}
                duration={500}
              >
                Technology
              </ScrollLink>

              <ScrollLink
                href="#enterprise"
                className={styles.link}
                activeClass={styles.activeLink}
                to="enterprise"
                spy={true}
                offset={-160}
                smooth={true}
                duration={500}
              >
                Enterprise
              </ScrollLink>

              <ScrollLink
                href="#faq"
                className={styles.link}
                activeClass={styles.activeLink}
                to="faq"
                spy={true}
                smooth={true}
                offset={-160}
                duration={500}
              >
                FAQ
              </ScrollLink>
            </div>
          )}

          {isHome && (
            <div
              aria-hidden={!this.state.isOpen}
              id="Navbar-mobile"
              className={cx('mobile', { mobileActive: this.state.isOpen })}
            >
              <div className={styles.mobileLinks}>
                <ScrollLink
                  href="#how-it-works"
                  onClick={this.onToggle}
                  className={cx('mobileLink', {
                    mobileLinkActive: this.state.isOpen,
                  })}
                  activeClass={styles.activeLink}
                  to="how-it-works"
                  spy={true}
                  offset={-109}
                  smooth={true}
                  duration={500}
                >
                  How It Works
                </ScrollLink>

                <ScrollLink
                  href="#technology"
                  onClick={this.onToggle}
                  className={cx('mobileLink', {
                    mobileLinkActive: this.state.isOpen,
                  })}
                  activeClass={styles.activeLink}
                  to="technology"
                  spy={true}
                  offset={-109}
                  smooth={true}
                  duration={500}
                >
                  Technology
                </ScrollLink>

                <ScrollLink
                  href="#enterprise"
                  onClick={this.onToggle}
                  className={cx('mobileLink', {
                    mobileLinkActive: this.state.isOpen,
                  })}
                  activeClass={styles.activeLink}
                  to="enterprise"
                  spy={true}
                  offset={-109}
                  smooth={true}
                  duration={500}
                >
                  Enterprise
                </ScrollLink>

                <ScrollLink
                  href="#faq"
                  onClick={this.onToggle}
                  className={cx('mobileLink', {
                    mobileLinkActive: this.state.isOpen,
                  })}
                  activeClass={styles.activeLink}
                  to="faq"
                  spy={true}
                  smooth={true}
                  offset={-109}
                  duration={500}
                >
                  FAQ
                </ScrollLink>
              </div>
            </div>
          )}
        </div>
      </nav>
    )
  }
}
