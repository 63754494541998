import React from 'react'
import Rellax from 'rellax'
import classNames from 'classnames/bind'

import styles from './Header.module.scss'

import Header from '../../../shared/Header'
import SubscriptionForm from '../SubscriptionForm'
import SubscriptionSuccess from '../SubscriptionSuccess'

const cx = classNames.bind(styles)

export default class IndexHeader extends React.Component {
  componentDidMount() {
    new Rellax('.rellax')
  }

  state = {
    isForm: true,
  }

  render() {
    return (
      <Header title="Start monitoring your cryptocurrency wallets 24/7">
        <div className={cx('wrapper', { wrapperSent: !this.state.isForm })}>
          {this.state.isForm && (
            <SubscriptionForm
              location={this.props.location}
              toggle={() => this.setState({ isForm: false })}
            />
          )}

          {!this.state.isForm && (
            <SubscriptionSuccess
              toggle={() => this.setState({ isForm: true })}
            />
          )}
        </div>
      </Header>
    )
  }
}
