import React from 'react'
import cx from 'classnames'
import SpinningLoaderIcon from '../../../assets/images/inline/ajax-loader.svg'

import './Loading.scss'

const Loading = props => {
  return (
    <SpinningLoaderIcon
      alt="This section is loading."
      className={cx('Loading', props.className)}
    />
  )
}

export default Loading
